import { NavSiteSection } from 'designsystem';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { GlobalsQuery } from '../gql/cms';
import { PillarNavigation } from 'shared';

const ProfessionalsNavigation: FC<{ globals: GlobalsQuery | null; isLoading: boolean }> = ({ globals, isLoading }) => {
    const { topLevelPages, childPages } = globals ?? {};
    const pages: NavSiteSection[] = useMemo(
        () =>
            topLevelPages?.map(parentPage => ({
                key: parentPage.id,
                href: `/${parentPage.uri}`,
                title: parentPage.title,
                subPages: childPages
                    .filter(page => page.parent?.id === parentPage.id)
                    .map(page => ({
                        key: page.id,
                        href: `/${page.uri}`,
                        title: page.title,
                    })),
            })) ?? [],
        [topLevelPages, childPages]
    );
    const { formatMessage } = useIntl();

    return (
        <PillarNavigation
            pillarKey="professionals"
            pages={pages}
            searchBarPlaceholder={formatMessage({
                defaultMessage: 'Search',
            })}
            overviewLabel={formatMessage({
                defaultMessage: 'Back to overview',
            })}
            myIdfaPath="/myidfa"
            showLanguageSwitch={false}
            isLoading={isLoading}
        />
    );
};

export default ProfessionalsNavigation;
